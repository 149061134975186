import { addSerialisedCookieSsr } from '../../cookies/cookies.ssr.helpers';
import {
    createLoggerContext,
    createTraceParameters,
    getAppLogger,
} from '../../logger';
import { getBaseURLFromRequest } from '../../url';
import { checkSessionApi, checkSessionSSR } from '../auth/session';
import { decode, verifyJwt } from '../auth/sessionToken/sessionToken';
import {
    ApiIHandlerInjector,
    GsspInjector,
    GsspLoggerInjectedProperties,
    GsspWithLoggerParameters,
    ApiHandlerWithLoggerParameters,
    GsspWithAuthParameters,
    GsspAuthInjectedProperties,
    ApiHandlerWithAuthParameters,
    ApiHandlerAuthInjectedProperties,
} from '../next.types';
import {
    gsspErrorHandler,
    nextApiErrorHandler,
} from './helpers/injectorsErrorHandlers';
import { DecodedAccessTokenPayload } from '@tgg/common-types';

export const gsspWithLogger: GsspInjector<
    GsspLoggerInjectedProperties,
    GsspWithLoggerParameters
> = (gssp, injectorParameters) => {
    return async context => {
        const {
            customErrorHandler,
            applicationIdentifier,
            meta: journeyLoggerParameters,
        } = injectorParameters;

        const loggerParameters = createTraceParameters({
            context,
            parameters: {
                operation: 'getServerSideProps',
                ...journeyLoggerParameters,
            },
        });

        const logger = getAppLogger({ applicationIdentifier });

        const { isMobileRequest, isMobileSession } = loggerParameters;
        try {
            return await gssp(context, {
                loggerParameters,
                isMobileRequest,
                logger,
                isMobileSession,
            });
        } catch (error) {
            if (customErrorHandler)
                return customErrorHandler({ error, logger, loggerParameters });

            return gsspErrorHandler({ error, logger, loggerParameters });
        }
    };
};

export const nextApiHandlerWithLogger: ApiIHandlerInjector<
    GsspLoggerInjectedProperties,
    ApiHandlerWithLoggerParameters
> = (handler, injectorParameters, injectedLogger) => {
    return async (request, response) => {
        const {
            customErrorHandler,
            applicationIdentifier,
            meta: journeyLoggerParameters,
        } = injectorParameters;

        const loggerParameters = createTraceParameters({
            context: createLoggerContext(request, response),
            parameters: {
                operation: 'apiHandler',
                ...journeyLoggerParameters,
            },
        });
        const logger =
            injectedLogger ?? getAppLogger({ applicationIdentifier });

        const { isMobileRequest, isMobileSession } = loggerParameters;

        try {
            return await handler(request, response, {
                loggerParameters,
                isMobileRequest,
                logger,
                isMobileSession,
            });
        } catch (error) {
            if (customErrorHandler)
                return customErrorHandler({
                    error,
                    logger,
                    loggerParameters,
                    response,
                });

            return nextApiErrorHandler({
                error,
                logger,
                loggerParameters,
                response,
            });
        }
    };
};

export const gsspWithAuth: GsspInjector<
    GsspAuthInjectedProperties,
    GsspWithAuthParameters
> = (gssp, injectorParameters) => {
    return async context => {
        const {
            customErrorHandler,
            applicationIdentifier,
            meta: journeyLoggerParameters,
            authClientGetter,
            env,
            permissions,
            isAccessibleFromMobileApp,
            isAccessibleFromWeb,
        } = injectorParameters;

        const loggerParameters = createTraceParameters({
            context,
            parameters: {
                operation: 'getServerSideProps',
                ...journeyLoggerParameters,
            },
        });

        const logger = getAppLogger({ applicationIdentifier });

        const { isMobileRequest, isMobileSession } = loggerParameters;

        try {
            const baseUrl = getBaseURLFromRequest(context?.req);
            const {
                data: { appSessionCookiesSerialized },
                error: checkSessionSSRError,
                redirect,
            } = await checkSessionSSR(
                context,
                permissions,
                { isAccessibleFromMobileApp, isAccessibleFromWeb },
                {
                    applicationIdentifier,
                    authClient: authClientGetter(baseUrl),
                    env,
                },
            );

            if (checkSessionSSRError) {
                logger.error(
                    (checkSessionSSRError as Error).message,
                    loggerParameters,
                );

                if (!redirect)
                    throw new Error(
                        'checkSessionSSR errored without explicit redirect',
                    );

                return { redirect };
            }

            if (redirect) {
                logger.info(
                    'checkSessionSSR returned redirect without explicit error',
                    loggerParameters,
                );
                return { redirect };
            }

            if (isMobileSession) {
                addSerialisedCookieSsr(context, appSessionCookiesSerialized);
            }

            return await gssp(context, {
                loggerParameters,
                isMobileRequest,
                logger,
                appSessionCookiesSerialized,
                isMobileSession,
            });
        } catch (error) {
            if (customErrorHandler)
                return customErrorHandler({ error, logger, loggerParameters });

            return gsspErrorHandler({ error, logger, loggerParameters });
        }
    };
};

export const nextApiHandlerWithAuth: ApiIHandlerInjector<
    ApiHandlerAuthInjectedProperties,
    ApiHandlerWithAuthParameters
> = (handler, injectorParameters) => {
    return async (request, response) => {
        const {
            customErrorHandler,
            applicationIdentifier,
            meta: journeyLoggerParameters,
            authClientGetter,
            permissions,
            isAccessibleUsingCustomSession,
            shouldReturnResponse = true,
            verifyToken = true,
        } = injectorParameters;

        const loggerParameters = createTraceParameters({
            context: createLoggerContext(request, response),
            parameters: {
                operation: 'apiHandler',
                ...journeyLoggerParameters,
            },
        });
        const logger = getAppLogger({ applicationIdentifier });

        const { isMobileRequest, isMobileSession } = loggerParameters;

        try {
            const baseUrl = getBaseURLFromRequest(request);
            const {
                data: { accessTokenForAPIRoute },
                error: checkSessionApiError,
                status: checkSessionApiStatus,
            } = await checkSessionApi(
                createLoggerContext(request, response),
                isAccessibleUsingCustomSession,
                permissions,
                {
                    authClient: authClientGetter(baseUrl),
                },
            );

            if (checkSessionApiError) {
                logger.error(
                    (checkSessionApiError as Error).message,
                    loggerParameters,
                );

                if (shouldReturnResponse) {
                    response.status(checkSessionApiStatus).json({
                        error: (checkSessionApiError as Error).message,
                    } as any);
                    return;
                }
            }
            if (verifyToken) {
                logger.debug(
                    `apiHandler - about to verify access token to ensure it's validity`,
                    loggerParameters,
                );
                await verifyJwt(accessTokenForAPIRoute);
            }

            logger.debug(
                `apiHandler - about to decode access token to get member_id`,
                loggerParameters,
            );
            const decodedAccessToken = decode(
                accessTokenForAPIRoute || '',
            ) as unknown as DecodedAccessTokenPayload;

            const memberId =
                decodedAccessToken['https://thegymgroup.com/claims/member_id'];

            // eslint-disable-next-line consistent-return
            return await handler(request, response, {
                loggerParameters,
                isMobileRequest,
                isMobileSession,
                rawAccessToken: accessTokenForAPIRoute,
                decodedAccessToken,
                memberId,
                logger,
            });
        } catch (error) {
            if (customErrorHandler)
                // eslint-disable-next-line consistent-return
                return customErrorHandler({
                    error,
                    logger,
                    loggerParameters,
                    response,
                });

            // eslint-disable-next-line consistent-return
            return nextApiErrorHandler({
                error,
                logger,
                loggerParameters,
                response,
            });
        }
    };
};
